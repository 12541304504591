<template>
  <div class="loginBg">
    <el-form
      :model="loginForm"
      :rules="rules"
      ref="loginForm"
      label-position="left"
      label-width="0px"
      class="demo-ruleForm login-container"
    >
      <h3 class="title">风电机组网络化协同设计与制造平台</h3>
      <el-form-item prop="username">
        <el-input
          type="text"
          v-model="loginForm.username"
          auto-complete="off"
          placeholder="账号"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          v-model="loginForm.password"
          auto-complete="off"
           show-password
          placeholder="密码"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item prop="usertype">
        <el-select v-model="loginForm.usertype" auto-complete="off">
          <el-option label="供应商" value="供应商"></el-option>
          <el-option label="海装风电" value="海装风电"></el-option>
          <el-option label="超级管理员" value="超级管理员"></el-option>
        </el-select>
      </el-form-item> -->
      <el-checkbox v-model="checked" :checked="checked" class="remember"
        >记住密码</el-checkbox
      >
      <el-form-item style="width: 100%">
        <el-button
          type="primary"
          style="width: 100%"
          @click="handleSubmit('loginForm')"
          :loading="loading"
          >登录
        </el-button>
      </el-form-item>
      <!-- <div class="register">
        <el-button @click="Register" type="text">还没账号，去注册？</el-button>
      </div> -->
    </el-form>
  </div>
</template>

<script>
import { encrypt, decrypt } from "@/utils/crypto.js";

import { userlogin } from "@/api/user.js";
import { mapMutations } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      loginForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      checked: false,
    };
  },
  beforeMount() {
    try {
      let checked = localStorage.getItem("checked");
      let password = decrypt(localStorage.getItem("password"));
      let username = decrypt(localStorage.getItem("username"));
      if (checked) {
        this.checked = true;
        this.loginForm.password = password ? password : "";
        this.loginForm.username = username ? username : "";
      }
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    handleSubmit(loginForm) {
      console.log(this.loginForm);
      console.log(this.checked);

      this.$refs[loginForm].validate(async (valid) => {
        if (valid) {
          JSON.stringify(this.loginForm);
          let login = await userlogin(this.loginForm);
          if (login.code == 200) {
            this.loading = true;
            this.$store.commit("ChangeLogin", login.data.token);
            localStorage.setItem("userType", login.data.userType);
            localStorage.setItem("name", login.data.name);
            localStorage.setItem('userId',login.data.userId)
            localStorage.setItem('company',login.data.company)
            if (this.checked) {
              localStorage.setItem(
                "username",
                encrypt(this.loginForm.username)
              );
              localStorage.setItem(
                "password",
                encrypt(this.loginForm.password)
              );
              localStorage.setItem("checked", this.checked);
            } else {
              localStorage.setItem('avatar',login.data.avatar)
              localStorage.removeItem("password");
              localStorage.removeItem("checked");
              localStorage.removeItem("username");
            }
            console.log(login.data);
            setTimeout(() => {
                this.$router.push('/');
              // this.$message.success("欢迎登录");
            }, 500);
          } else {
            this.$message({
              type: "error",
              message: login.message,
            });
          }
        } else if (!username) {
          return this.$message.error("请输入账号");
        } else if (!password) {
          return this.$message.error("请输入密码");
        } else {
          this.$message.error("账号或密码错误");
        }
      });
    },
    // Register() {
    //   this.$router.push("/Register");
    // },
  },
};
</script>

<style lang="scss" scoped>
.loginBg {
  width: 100%;
  height: 100%;
  //background: #2B3F54;
  // background-image: url(../assets/bg1.jpg)
  background: url(../assets/img/logback.jpg) no-repeat;
  background-size: cover;
}

.login-container {
  /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  // background-clip: padding-box;
  position: absolute;
  width: 350px;
  height: 350px;
  left: 50%;
  top: 50%;
  margin-left: -185px;
  margin-top: -180px;
  // margin: 0 auto;
  padding: 35px 35px 15px 35px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  // box-shadow: 0 0 25px #cac6c6;
  .title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
  }

  .remember {
    margin: 0px 0px 35px 0px;
  }
}
.register {
  width: 200px;
  height: 200px;
  color: aqua;
}
</style>